import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import Seo from '../components/Seo'
import Text from '../images/live-life-like-ted.inline.svg'

export default function About({data}) {
  return (
    <div className='about'>
      <Seo 
        description={`Hi I’m Lucy, a certified DTC professional dog trainer who uses modern science based and positive training methods. I provide a bespoke dog walking service, as well as wellness toolkits, so your dog can live life like Ted! `}
        image={'/og.jpg'}
        title={'About | The Enriched Dog | Dog Walking Leeds'}
      />
      <div className="intro">
        <h1>About</h1>
        <div className="text">
          <div className="inner">Hi I'm Lucy, a certified DTC professional dog trainer. I provide a bespoke dog walking service, as well as wellness toolkits, so your dog can...</div>
        </div>
        <div className='svg-wrapper'>
          <Text />
        </div>
        <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt="Lucy - TED: The Enriched Dog" />
        <div className="text">
          <div className="inner">
            <p>My approach involves modern science-based training methods, positive reinforcement and a focus on making sure your dog's physical, mental and emotional needs are met.</p>
            <p>Since my sidekick Chewy came to live with me 7 years ago, I have learnt a lot through understanding and managing her reactivity as well as emotional needs, helping her build confidence and the impact her mental wellbeing can have on her day to day behaviour.</p>
            <p>I have a passion for enriching the lives of all dogs, especially those who are <strong>anxious</strong>, <strong>fearful</strong> and in general find the world a bit <strong>overwhelming</strong>.</p> </div>
        </div>
      </div>
    </div>
  )
}


export const AboutQuery = graphql`
  query AboutQuery { 
    image:  file(relativePath:{eq:"about.jpg"}) {
              childImageSharp {
                gatsbyImageData
              }
            }
  }
`